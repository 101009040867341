.form-error {
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}

.form-error-major {
  margin-left: 0.2em;
  color: red;
  font-size: 1.1rem;
  -webkit-animation: fadeOut 8s;
  animation: fadeOut 8s;
  animation-fill-mode: forwards;
}

.message-success {
  margin-left: 0.2em;
  color: green;
  font-size: 1.1rem;
  -webkit-animation: fadeOut 8s;
  animation: fadeOut 8s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
